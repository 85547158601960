import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: '/',
    name: 'MainPagePrograms',
    component: () => import('@/components/ProgramsPage.vue'),
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/components/AboutUs.vue'),
  },
  // {
  //   path: '/faq',
  //   name: 'FAQpage',
  //   component: () => import('@/components/FAQ/FAQpage.vue'),
  // },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('@/components/Contacts.vue'),
  },
  {
    path: '/program/:id',
    name: 'Program',
    component: () => import('@/components/Programs/ProgramPage.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    redirect: '/profile/buy',
  },
  {
    path: '/purchased-product/:id',
    name: 'PurchasedProduct',
    component: () => import('@/components/Product/PurchasedProduct.vue'),
  },
  {
    path: '/marketplace',
    name: 'ProgramsPage',
    component: () => import('@/components/ProgramsPage.vue'),
  },
  {
    path: '/legal-notice',
    name: 'TermsAndConditions',
    component: () => import('@/components/Policy/TermsAndConditions.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/components/Policy/PrivacyPolicy.vue'),
  },
  // {
  //   path: '/cookie-policy',
  //   name: 'CookiePolicy',
  //   component: () => import('@/components/Policy/CookiePolicy.vue'),
  // },
  // {
  //   path: '/cancellation-policy',
  //   name: 'CancellationPolicy',
  //   component: () => import('@/components/Policy/CancellationPolicy.vue'),
  // },
  // {
  //   path: '/refund-policy',
  //   name: 'RefundPolicy',
  //   component: () => import('@/components/Policy/RefundPolicy.vue'),
  // },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('@/components/Product/ProductPage.vue'),
  },
  {
    path: '/authorization',
    name: 'Authorization',
    redirect: '/authorization/login',
  },
  {
    path: '/authorization/login',
    name: 'AuthorizationLogin',
    components: {
      default: () => import('@/components/Auth/Login.vue'),
    },
  },
  {
    path: '/authorization/registration',
    name: 'AuthorizationRegistration',
    components: {
      default: () => import('@/components/Auth/Registration.vue'),
    },
  },
  {
    path: '/profile/buy',
    name: 'ProfileBuy',
    components: {
      default: () => import('@/components/Profile/ProfileBuy.vue'),
    },
  },
  {
    path: '/profile/transactions',
    name: 'ProfileTransactions',
    components: {
      default: () => import('@/components/Profile/ProfileTransactions.vue'),
    },
  },
  {
    path: '/profile/billing-address',
    name: 'ProfileBillingAddress',
    components: {
      default: () => import('@/components/Profile/ProfileBillingAddress.vue'),
    },
  },
  {
    path: '/profile/purchased-items',
    name: 'ProfilePurchasedItems',
    components: {
      default: () => import('@/components/Profile/ProfilePurchasedItems.vue'),
    },
  },
  {
    path: '/profile/details',
    name: 'ProfileDetails',
    components: {
      default: () => import('@/components/Profile/ProfileDetails.vue'),
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/profile')) {
    const user = localStorage.getItem('user');
    const isAuthenticated = !!user;

    if (isAuthenticated) {
      next();
    } else {
      next('/authorization/login');
    }
  } else {
    next();
  }
  if (to.path.startsWith('/purchased-product')) {
    const user = localStorage.getItem('user');
    const isAuthenticated = !!user;

    if (isAuthenticated) {
      next();
    } else {
      next('/authorization/login');
    }
  } else {
    next();
  }
});

export default router;
