<script setup>
</script>

<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "scss/app";
@import "scss/variables";
@import "scss/mixins";

.app {
  display: flex;
  align-items: stretch;
  overflow-x: hidden;
  flex: 1;
  min-height: 100vh;
  width: 100%;
  font-family: "Manrope", sans-serif;
}
</style>
